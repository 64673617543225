import React from 'react';
import logo from '../assets/logo.png';
import Typewriter from 'typewriter-effect';

const ComingSoon = () => {

    return (
        <div className="h-screen flex flex-col items-center justify-center relative perspective-1000">
            <div className="flex justify-center">
                <img src={logo} className="w-100 h-28" alt="Logo" />
            </div>
            <div className='flex flex-row ubuntu-sans-text text-lg py-4'>

                <Typewriter
                    options={{
                        strings: ['UNDER CONSTRUCT!', 'WORK IN PROGRESS!', 'WORKING ON!', 'LAUNCHING SOON!', , 'STAY TUNED!'],
                        autoStart: true,
                        loop: true,
                    }}
                />            
                </div>
        </div>
    );
};

export default ComingSoon;
