
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';


const Main = () => {
  const under_construction = true;

  return (
    <div className="relative h-screen flex flex-col items-center justify-center">
      {under_construction ? <ComingSoon /> : <Home /> }
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;
