import React, { useState } from 'react';
import RollerMenu from '../components/RollerMenu';
import { animated, useTransition } from '@react-spring/web';
const Home = () => {
  const [currentPage, setCurrentPage] = useState('home');

  const handleItemClick = (item) => {
    const page = item.toLowerCase();
    setCurrentPage(page);
    console.log('Clicked:', page);
  };


  const Products = () => <div className="p-4 bg-green-200">Products Content</div>;
  const Solutions = () => <div className="p-4 bg-green-200">Solutions Content</div>;
  const Ecosystem = () => <div className="p-4 bg-green-200">Ecosystem Content</div>;
  const Resources = () => <div className="p-4 bg-green-200">Resources Content</div>;

  const About = () => <div className="p-4 bg-blue-200">About Content</div>;
  const Contact = () => <div className="p-4 bg-yellow-200">Contact Content</div>;

  const PageContent = ({ currentPage }) => {
    switch (currentPage) {
      case 'about':
        return <About />;
      case 'products':
        return <Products />;
      case 'solutions':
        return <Solutions />;
      case 'ecosystem':
        return <Ecosystem />;
      case 'resources':
        return <Resources />;
      case 'contact':
        return <Contact />;
      default:
        return null;
    }
  };

  const transitions = useTransition(currentPage, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    config: { duration: 300 }
  });
  return (
    <div className="relative h-screen bg-gray-100">
      <div className="absolute inset-0 flex items-center justify-center">
        {currentPage === 'home' ? (
          <RollerMenu onItemClick={handleItemClick} items={['Products', 'Solutions', 'Ecosystem', 'Resources', 'About', 'Contact']} />
        ) : (
          transitions((style, item) => (
            item !== 'home' && (
              <animated.div
                style={{
                  ...style,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <PageContent currentPage={item} />
              </animated.div>
            )
          ))
        )}
      </div>

    </div>
  );
};

export default Home;